<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-distributor
          class="mr-2"
          v-model:value="distributors"
          v-model:distributor="distributors"
        ></filter-distributor>
        <filter-fungsi class="mr-2" v-model:value="fungsi"></filter-fungsi>
        <filter-jenis
          class="mt-2 mr-2"
          placeholder="Pilih Type"
          v-model:value="type"
          :mode="null"
        ></filter-jenis>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button
          class="mt-2 mr-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          class="m-2"
          type="primary"
          title="Tambah Master Api Distributor"
          @click="
            () => {
              formState = {}
              setModal1Visible(true)
              formState.title_state = 'Tambah Master Api Distributor'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
        <a-button
          type="primary"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
            <div class="table-responsive ">
              <a-table
                :columns="columns"
                :data-source="data"
                size="small"
                :row-selection="rowSelection"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                bordered
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template
                  #filterDropdown="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                >
                  <div class="custom-filter-dropdown">
                    <a-input
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block"
                      @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                      @pressEnter="() => handleSearch(selectedKeys, confirm)"
                    />
                    <a-button
                      type="primary"
                      size="small"
                      style="width: 90px; margin-right: 8px"
                      @click="() => handleSearch(selectedKeys, confirm)"
                      >Search</a-button
                    >
                    <a-button
                      size="small"
                      style="width: 90px"
                      @click="() => handleReset(clearFilters)"
                      >Reset</a-button
                    >
                  </div>
                </template>
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>
                <template #customer="{ text }">
                  <span v-if="searchText">
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                      <mark
                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
                  </template>
                </template>
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #progress="{ text: bar }">
                  <div class="progress">
                    <div
                      :class="['progress-bar', bar.color]"
                      :style="{ width: bar.value + '%' }"
                      role="progressbar"
                    ></div>
                  </div>
                </template>
                <template #value="{ text }">
                  <span class="font-weight-bold">{{ text }}</span>
                </template>
                <template #id="{ text }">
                  <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                </template>
                <template #total="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #tax="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #shipping="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #action="{record}">
                  <div class="d-flex align-items-center justify-content-between">
                    <a-tooltip title="Lihat Data">
                      <a
                        href="javascript: void(0);"
                        class="btn btn-sm btn-light"
                        @click="lihatData(record.id)"
                      >
                        <small>
                          <i class="fe fe-eye" />
                        </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Ubah Data">
                      <a
                        href="javascript: void(0);"
                        class="btn btn-sm btn-light"
                        @click="editData(record.id)"
                      >
                        <small>
                          <i class="fe fe-edit" />
                        </small>
                      </a>
                    </a-tooltip>
                  </div>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder">
          <div class="card-body"><vb-controls-pagination /></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder">
          <div class="card-body"><vb-controls-pagination-2 /></div>
        </div>
      </div>
    </div>
    <a-modal
      v-model:visible="modal1Visible"
      :title="formState.title_state"
      width="700px"
      :destroy-on-close="true"
      :mask-closable="false"
      @ok="modal1Visible = false"
    >
      <pre v-if="!true">{{ formState }}</pre>
      <template #footer>
        <a-button
          :loading="isPosting"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >Simpan</a-button>
      </template>
      <a-form
        v-if="modal1Visible"
        :rules="rules"
        :model="formState"
        :wrapper-col="{ span: 14 }"
        class="myform"
      >
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Tipe"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              required
              allow-clear
              name="type"
            >
              <filter-jenis
                style="width: 100%"
                v-model:value="formState.type"
                :disabled="formState.readOnly"
                :mode="null"/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Fungsi"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              required
              allow-clear
              name="function"
            >
              <filter-fungsi style="width: 100%" mode="" v-model:value="formState.function" :disabled="formState.readOnly"></filter-fungsi>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="URL"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              required
              has-feedback
              name="url"
            >
              <a-input
                width="100%"
                v-model:value="formState.url"
                :disabled="formState.readOnly"
                required
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Metode"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              required
              has-feedback
              name="method"
            >
              <a-input
                width="100%"
                v-model:value="formState.method"
                :disabled="formState.readOnly"
                required
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Token"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              has-feedback
              name="token"
            >
              <a-input
                width="100%"
                v-model:value="formState.token"
                :disabled="formState.readOnly"
                required
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Distributor"
              label-align="left"
              :label-col="{ sm: { span: 6 } }"
              :wrapper-col="{ sm: { span: 24 - 6 } }"
              name="vendor_id"
              allow-clear
            >
              <select-distributor
                show-search
                style="width: 100%"
                v-model:value="formState.vendor_id"
                label="fullname"
                mode=""
                :disabled="formState.readOnly"
                :join-options="formState.vendor_name"
                required
              ></select-distributor>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, reactive, toRefs, computed } from 'vue'

import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'

import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterFungsi from '@/components/filter/FilterFungsi'
import SelectDistributor from '@/components/select/SelectDistributor'
import FilterJenis from '@/components/filter/FilterJenisApi'

import qs from 'qs'
import moment, { Moment } from 'moment'
import store from '@/store'
import acolumns from './columns'
import rules from './rules.js'

import { useDisabledDate } from '@/composables/DisabledDate'

import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

// function renderVNode(_, { attrs: { vnode } }) {
// return vnode
// }
export default defineComponent({
  components: {
    // FilterRegional,
    // FilterProvince,
    // FilterArea,
    // FilterKabupatenKota,
    FilterDistributor,
    SelectDistributor,
    // FilterProducts,
    // FilterBrands,
    FilterFungsi,
    FilterJenis,
  },
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const current1 = ref(1)

    const type = ref(null)
    const fungsi = ref([])

    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const isPosting = ref(false)

    const q = ref('')
    const distributors = ref([])
    const dateFormat = 'YYYY-MM-DD';
    const formState = ref({})
    const vendorId = ref(store.state.user.vendor_id)

    const errorMessage = ref(null)
    const {
      startDate,
      endDate,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()

    columns.value = acolumns

    const queryParams = () => {
      let params = {
        page: page.value,
        'per-page': perPage.value,
        distributor: distributors.value,
        q: q.value,
        type: type.value,
        fungsi: fungsi.value,
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = () => {
      state.isFetching = true
      apiClient
        .get('/api/vendor-rest-apies', {
          params: queryParams(),
        })
        .then(response => {
          const { items, _meta } = response.data
          items.forEach((item, i) => {
            item.key = i
          })
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/vendor-rest-apies', {
          params: {
            ...queryParams(),
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `master-api-distributor_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const submit = async () => {
      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi Tambah',
          content: 'Apakah anda ingin melanjutkan tambah?',

          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            apiClient
              .post('/api/vendor-rest-apies', formState.value)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal menyimpan'))
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Edit',
          content: 'Apakah anda ingin melanjutkan Edit?',
          onOk() {
            isPosting.value = true
            const form_data = new FormData()
            for (var key in formState.value) {
              form_data.append(key, formState.value[key])
            }
            // TODO: validasi
            apiClient
              .post('/api/vendor-rest-apies/' + formState.value.id + '?_method=PUT', formState.value)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal menyimpan'))
              .finally(e => {
                isPosting.value = false
              })
          },
          onCancel() {},
        })
      }
    }

    const lihatData = id => {
      apiClient
        .get(`/api/vendor-rest-apies/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Lihat API Distributor'
          formState.value.readOnly = true
        })
        .catch(error => console.error(error))
    }
    const editData = id => {
      apiClient
        .get(`/api/vendor-rest-apies/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          formState.value.title_state = 'Edit API Distributor'
          formState.value.readOnly = false
          formState.value.state = 'edit'
          modal1Visible.value = true
        })
        .catch(error => console.error(error))
    }

    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }
    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    onMounted(() => {
      let params = extractQueryParams({
        page: page.value,
        'per-page': perPage.value,
        distributor: distributors.value,
        q: q.value,
        type: type.value,
        fungsi: fungsi.value,
      })

      page.value = params.page
      perPage.value = params['per-page']
      distributors.value = params.distributor
      q.value = params.q
      type.value = params.type
      fungsi.value = params.fungsi

      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const onSearch = value => {
      findText.value = value
    }

    return {
      modal1Visible,
      setModal1Visible,
      modal2Visible,
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      q,
      handleTableChange,
      startRow,
      // onSelectChange,
      state,
      fetchXlsx,
      distributors,
      fetchData,
      ...toRefs(state),
      search,
      //  rowSelection,
      type,
      fungsi,
      errorMessage,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
      dateFormat,
      formState,
      onSearch,
      vendorId,
      lihatData,
      editData,
      submit,
      isPosting,
      rules,
    }
  },
})
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>

<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
.myform :deep(div:not(.has-error) > .ant-form-explain) {
  color: #262d3d;
}
</style>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
